
#Category {
    min-height: 50vh;
}

// .steps {
//           li {
//                     &::before {
//                               position: absolute;
//                               content: '';
//                               right: 0;
//                               width: 100%;
//                               top: calc(50% - 1.25rem);
//                               border: 1px dashed #888;
//                     }
//                     &:last-child,
//                     &:first-child{
//                               &::before { 
//                                         width: 50%; 
//                               }    
//                     }
//                     &:last-child{
//                               &::before { 
//                                         left: 0;
//                                         right: auto;
//                               }    
//                     }
//                     &.active{
//                               &::before { 
//                                         border: 1px dashed #1EC2E3;
//                               }
//                     }
//           }
// }


.steps .nav::before {
    z-index: -1;
    content: '';
    position: absolute;
    width: 96%;
    left: 3%;
    top: 50%;
    height: 2px;
    border: 1px dashed #d2d2d2;
}

/* The radioBox */
.radioBox {
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    margin-right: 2rem;
    cursor: pointer;
    font-size: 18px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.radioBox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radioBox:hover input~.checkmark {
    background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radioBox input:checked~.checkmark {
    background-color: #2196F3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioBox input:checked~.checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radioBox .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

@keyframes ldio-dlp895cf7od {
    0% {
        opacity: 1
    }

    100% {
        opacity: 0
    }
}

.ldio-dlp895cf7od div {
    left: 57.82px;
    top: 24.779999999999998px;
    position: absolute;
    animation: ldio-dlp895cf7od linear 1s infinite;
    background: #1ec2e3;
    width: 2.36px;
    height: 14.16px;
    border-radius: 1.18px / 4.8144px;
    transform-origin: 1.18px 34.22px;
}

.ldio-dlp895cf7od div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.9615384615384616s;
    background: #1ec2e3;
}

.ldio-dlp895cf7od div:nth-child(2) {
    transform: rotate(13.846153846153847deg);
    animation-delay: -0.9230769230769231s;
    background: #1ec2e3;
}

.ldio-dlp895cf7od div:nth-child(3) {
    transform: rotate(27.692307692307693deg);
    animation-delay: -0.8846153846153846s;
    background: #1ec2e3;
}

.ldio-dlp895cf7od div:nth-child(4) {
    transform: rotate(41.53846153846154deg);
    animation-delay: -0.8461538461538461s;
    background: #1ec2e3;
}

.ldio-dlp895cf7od div:nth-child(5) {
    transform: rotate(55.38461538461539deg);
    animation-delay: -0.8076923076923077s;
    background: #1ec2e3;
}

.ldio-dlp895cf7od div:nth-child(6) {
    transform: rotate(69.23076923076923deg);
    animation-delay: -0.7692307692307693s;
    background: #1ec2e3;
}

.ldio-dlp895cf7od div:nth-child(7) {
    transform: rotate(83.07692307692308deg);
    animation-delay: -0.7307692307692307s;
    background: #1ec2e3;
}

.ldio-dlp895cf7od div:nth-child(8) {
    transform: rotate(96.92307692307692deg);
    animation-delay: -0.6923076923076923s;
    background: #1ec2e3;
}

.ldio-dlp895cf7od div:nth-child(9) {
    transform: rotate(110.76923076923077deg);
    animation-delay: -0.6538461538461539s;
    background: #1ec2e3;
}

.ldio-dlp895cf7od div:nth-child(10) {
    transform: rotate(124.61538461538461deg);
    animation-delay: -0.6153846153846154s;
    background: #1ec2e3;
}

.ldio-dlp895cf7od div:nth-child(11) {
    transform: rotate(138.46153846153845deg);
    animation-delay: -0.5769230769230769s;
    background: #1ec2e3;
}

.ldio-dlp895cf7od div:nth-child(12) {
    transform: rotate(152.30769230769232deg);
    animation-delay: -0.5384615384615384s;
    background: #1ec2e3;
}

.ldio-dlp895cf7od div:nth-child(13) {
    transform: rotate(166.15384615384616deg);
    animation-delay: -0.5s;
    background: #1ec2e3;
}

.ldio-dlp895cf7od div:nth-child(14) {
    transform: rotate(180deg);
    animation-delay: -0.46153846153846156s;
    background: #1ec2e3;
}

.ldio-dlp895cf7od div:nth-child(15) {
    transform: rotate(193.84615384615384deg);
    animation-delay: -0.4230769230769231s;
    background: #1ec2e3;
}

.ldio-dlp895cf7od div:nth-child(16) {
    transform: rotate(207.69230769230768deg);
    animation-delay: -0.38461538461538464s;
    background: #1ec2e3;
}

.ldio-dlp895cf7od div:nth-child(17) {
    transform: rotate(221.53846153846155deg);
    animation-delay: -0.34615384615384615s;
    background: #1ec2e3;
}

.ldio-dlp895cf7od div:nth-child(18) {
    transform: rotate(235.3846153846154deg);
    animation-delay: -0.3076923076923077s;
    background: #1ec2e3;
}

.ldio-dlp895cf7od div:nth-child(19) {
    transform: rotate(249.23076923076923deg);
    animation-delay: -0.2692307692307692s;
    background: #1ec2e3;
}

.ldio-dlp895cf7od div:nth-child(20) {
    transform: rotate(263.0769230769231deg);
    animation-delay: -0.23076923076923078s;
    background: #1ec2e3;
}

.ldio-dlp895cf7od div:nth-child(21) {
    transform: rotate(276.9230769230769deg);
    animation-delay: -0.19230769230769232s;
    background: #1ec2e3;
}

.ldio-dlp895cf7od div:nth-child(22) {
    transform: rotate(290.7692307692308deg);
    animation-delay: -0.15384615384615385s;
    background: #1ec2e3;
}

.ldio-dlp895cf7od div:nth-child(23) {
    transform: rotate(304.61538461538464deg);
    animation-delay: -0.11538461538461539s;
    background: #1ec2e3;
}

.ldio-dlp895cf7od div:nth-child(24) {
    transform: rotate(318.46153846153845deg);
    animation-delay: -0.07692307692307693s;
    background: #1ec2e3;
}

.ldio-dlp895cf7od div:nth-child(25) {
    transform: rotate(332.3076923076923deg);
    animation-delay: -0.038461538461538464s;
    background: #1ec2e3;
}

.ldio-dlp895cf7od div:nth-child(26) {
    transform: rotate(346.15384615384613deg);
    animation-delay: 0s;
    background: #1ec2e3;
}

.loadingio-spinner-spinner-b5p7g8eokig {
    width: 118px;
    height: 118px;
    display: inline-block;
    overflow: hidden;
    background: #ffffff;
}

.ldio-dlp895cf7od {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0;
    /* see note above */
}

.ldio-dlp895cf7od div {
    box-sizing: content-box;
}

/* generated by https://loading.io/ */



.text-overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
}
.professionalsCard *{
    transition: all 0.2s ease-in-out;
}

@media only screen and (min-width: 1536px) and (max-width: 1680px)  {
   .container{
    padding-left: 4rem ;
    padding-right: 4rem ;
   }
   
}

.mainBtn {
    display: flex;
    font-size: 1.7rem;
    align-items: center;
    color: #1ec2e3;
    border: 2px solid #fff;
    padding: 1rem 2rem;
    border-radius: 1rem;
    font-weight: 600;
    background: #fff;
    position: relative; 
    &::after {
        content: '';
        position: absolute;
        background: transparent;
        border: 3px solid #fff;
        top: -1.6rem;
        width: calc(100% + 3rem);
        left: -1.6rem;
        height: calc(100% + 3rem);
        border-radius: 2rem;
    }
    &.blueBtn{
        color: #fff;
        border: 2px solid #1ec2e3;
        background: #1ec2e3;    
        &::after {
            border: 3px solid #1ec2e3; 
        }
    }
    &.gray{
        color: #fff;
        border: 2px solid #888;
        background: #888;    
        &::after {
            border: 3px solid #888; 
        }
    }
}

.bg-mainColor.done{
    background-color: #1ec2e3;
}