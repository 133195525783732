@media screen and (min-width : 768px) {
          #BestServices {
                    .card {
                              &:nth-child(even) {
                                        margin-top: 4rem;
                              }

                              &:hover {

                                        p,
                                        h3 {
                                                  color: #fff;
                                        }
                              }
                    }
          }
}

.avatar {
          background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgb(30 194 227) 43%, rgba(255, 255, 255, 1) 100%);
}